import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  moveTable(id, { hallId, tableNumber }) {
    return axiosIns.put(
      `/waiter/tables/${id}/move`,
      snakecaseKeys({ hallId, tableNumber }),
    )
  },
  getTables({
    date = null,
    interval = null,
    hallId = null,
    tableNumber = null,
    customerTagId = null,
    referralId = null,
    tableId = null,
    endDate = null,
  }) {
    return axiosIns.get(
      '/waiter/tables',
      {
        params: snakecaseKeys({
          date,
          interval,
          hallId,
          tableNumber,
          customerTagId,
          referralId,
          tableId,
          endDate,
        }),
      },
    )
  },
  getTable(tableId) {
    return axiosIns.get(`/waiter/tables/${tableId}`)
  },
  createTable({
    hallId,
    customerCount,
    tableNumber,
    referringHistories,
    vendingHistories,
    customerTableTaggings,
  }) {
    const formData = serialize(snakecaseKeys({
      table: {
        hallId,
        customerCount,
        tableNumber,
        referringHistoriesAttributes: referringHistories,
        vendingHistoriesAttributes: vendingHistories,
        customerTableTaggingsAttributes: customerTableTaggings,
      },
    }))

    return axiosIns.post(`/waiter/halls/${hallId}/tables/`, formData)
  },
  updateTable({ tableId, params }) {
    const formData = serialize(snakecaseKeys({ table: params }))

    return axiosIns.put(`/waiter/tables/${tableId}/`, formData)
  },
  calcTotal({ tableId, price, orderPrice }) {
    return axiosIns.get(
      `/waiter/tables/${tableId}/calc_total`,
      {
        params: {
          price,
          order_price: orderPrice,
        },
      },
    )
  },
  calcProcessingFee({ tableId, price }) {
    return axiosIns.get(
      `/waiter/tables/${tableId}/calc_processing_fee`,
      {
        params: {
          price,
        },
      },
    )
  },
  calcTax({ tableId, price }) {
    return axiosIns.get(`/waiter/tables/${tableId}/calc_tax`, { params: { price } })
  },
  deleteTable(id) {
    return axiosIns.delete(`/waiter/tables/${id}`)
  },
}
