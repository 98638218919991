import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createBreakTimecard({ timecardId, breakTimeStartAt = null, breakTimeEndAt = null }) {
    return axiosIns.post(
      '/v2/eod_closing/break_timecards',
      snakecaseKeys({
        timecardId,
        breakTimeStartAt,
        breakTimeEndAt,
      }),
    )
  },
  updateBreakTimecard({
    id,
    timecardId,
    breakTimeStartAt = null,
    breakTimeEndAt = null,
  }) {
    return axiosIns.put(
      `/v2/eod_closing/break_timecards/${id}`,
      snakecaseKeys({
        timecardId,
        breakTimeStartAt,
        breakTimeEndAt,
      }),
    )
  },
  destroyBreakTimecard(id, timecardId) {
    return axiosIns.delete(
      `/v2/eod_closing/break_timecards/${id}`,
      { params: snakecaseKeys({ timecardId }) },
    )
  },
}
