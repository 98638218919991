import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getTimecards(
    date,
    {
      userId = null,
      role = null,
      timecardStatusId = null,
    } = {},
  ) {
    return axiosIns.get(
      '/v2/eod_closing/timecards',
      {
        params: snakecaseKeys({
          date,
          ...(userId != null && { userId }),
          ...(role != null && { role }),
          ...(timecardStatusId != null && { timecardStatusId }),
        }),
      },
    )
  },
  createTimecard({
    userId,
    startAt,
    leaveAt = null,
    lateMinutes = null,
    earlyLeaveMinutes = null,
    timecardStatusId = null,
  }) {
    if (!userId || !startAt) throw new Error('userId and startAt are required')

    return axiosIns.post(
      '/v2/eod_closing/timecards',
      snakecaseKeys({
        userId,
        startAt,
        leaveAt,
        lateMinutes,
        earlyLeaveMinutes,
        timecardStatusId,
      }),
    )
  },
  updateTimecard(id, params = {}) {
    const {
      startAt,
      leaveAt,
      lateMinutes,
      earlyLeaveMinutes,
      timecardStatusId,
    } = params

    return axiosIns.put(
      `/v2/eod_closing/timecards/${id}`,
      snakecaseKeys({
        startAt,
        leaveAt,
        lateMinutes,
        earlyLeaveMinutes,
        timecardStatusId,
      }),
    )
  },
  destroyTimecard(id) {
    return axiosIns.delete(`/v2/eod_closing/timecards/${id}`)
  },
}
