import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getAggregations(date, options = {}) {
    const { forceReload = false } = options

    return axiosIns.get(
      '/v2/eod_closing/aggregations',
      { params: snakecaseKeys({ date, forceReload }) },
    )
  },
  enqueueAggregation(id, date, options = {}) {
    const { forceReload = false, forceRefresh = false } = options

    return axiosIns.get(
      `/v2/eod_closing/aggregations/${id}/enqueue`,
      { params: snakecaseKeys({ date, forceReload, forceRefresh }) },
    )
  },
  getAggregationJobStatus(id, date) {
    return axiosIns.get(
      `/v2/eod_closing/aggregations/${id}/status`,
      { params: { date } },
    )
  },
  getAvailableUsers(id) {
    return axiosIns.get(`/v2/eod_closing/aggregations/${id}/available_users`)
  },
}
