import axiosIns from '@/plugins/axios'

export default {
  getToday() {
    return axiosIns.get('/v2/utility/times/today')
  },
  getEndTimeOfDay(date) {
    return axiosIns.get('/v2/utility/times/end_time_of_day', { params: { date } })
  },
}
