import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createAggregationUser(aggregationId, userId) {
    return axiosIns.post(
      '/v2/eod_closing/aggregation_users',
      snakecaseKeys({ aggregationId, userId }),
    )
  },
  destroyAggregationUser(id) {
    return axiosIns.delete(`/v2/eod_closing/aggregation_users/${id}`)
  },
}
