import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  enqueueGenerateBusinessRuleResults(aggregationId, date, options = { forceReload: false }) {
    return axiosIns.post(
      `/v2/eod_closing/business_rules/generate_results/${aggregationId}`,
      snakecaseKeys({
        date,
        ...options,
      }),
    )
  },
  generateBusinessRuleResult({
    aggregationId,
    contextType,
    contextId,
    businessRuleId,
    resultValue,
    date,
    dependents,
    options = { forceReload: false },
  }) {
    return axiosIns.post(
      `/v2/eod_closing/business_rules/${businessRuleId}/generate_result/${aggregationId}`,
      snakecaseKeys({
        contextType,
        contextId,
        resultValue,
        date,
        dependents,
        ...options,
      }),
    )
  },
}
