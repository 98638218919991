const toLowerStringAnyway = val => `${val?.toString()?.toLowerCase()}`
/**
 * 判定した値の型を返す関数
 * @param {*} val - 判定対象の値
 * @returns {string} - 'boolean', 'number', または 'text'
 */
export const judgeType = val => {
  const str = toLowerStringAnyway(val)

  if (['true', 'false'].includes(str)) return 'boolean'

  if (!Number.isNaN(Number(str))) return 'number'

  return 'text'
}

/**
 * 値を適切な型に変換する関数
 * @param {*} val - 変換対象の値
 * @returns {*} - boolean, number, または text
 */
export const interpretValue = val => {
  const type = judgeType(val)

  switch (type) {
    case 'boolean':
      return toLowerStringAnyway(val) === 'true'
    case 'number':
      return Number(val)
    default:
      return val
  }
}
